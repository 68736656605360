export const panel = {
  background: 'white',
  borderRadius: 5
};

export const selectStyle = {
  width: 340,
  backgroundColor: 'white',
  borderRadius: 10
};

export const arrowStyle = {
  color: 'black',
  fontSize: 15,
  pointerEvents: 'none' as const
};
