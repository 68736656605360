import { FC, useState } from 'react';
import { Col, Row, Divider } from 'antd';
import GridCard from '../../components/GridCard/GridCard';
import { icon } from './styles';
import { baseStyle } from '../../shared/styles/baseStyles';
import { AlertOutlined } from '@ant-design/icons';
import MainMap from '../../components/MainMap/MainMap';
import MainTable from '../../components/MainTable/MainTable';
import { Typography } from 'antd';
import SensorDetailsContainer from '../SensorDetails/SensorDetailsContainer';
import ClimateTypeControl from '../../components/MapControls/ClimateTypeControl';

const { Text } = Typography;

const Home: FC = () => {
  const [collapsed, setCollapsed] = useState(false);

  const openLayout = () => {
    setCollapsed(true);
  };

  const closeLayout = () => {
    setCollapsed(false);
  };

  return (
    <>
      <Row justify="center" align="top">
        <Col xs={23} md={23} lg={!collapsed ? 23 : 14}>
          <GridCard
            title={
              <Text style={baseStyle.mainCardHeader}>Απεικόνιση Μετρήσεων</Text>
            }
            extra
          >
            <Row>
              <Col xs={23}>
                <ClimateTypeControl />
              </Col>
            </Row>
            <MainMap openLayout={openLayout} collapsed={collapsed} />
          </GridCard>
        </Col>
        {collapsed && (
          <>
            <Divider type="vertical" />
            <Col lg={9} md={23} xs={24}>
              <SensorDetailsContainer closeLayout={closeLayout} />
            </Col>
          </>
        )}
      </Row>
      <Divider />
      <Row justify="center">
        <Col span={23}>
          <GridCard
            title={
              <>
                <AlertOutlined style={icon} />
                <Text style={baseStyle.mainCardHeader}>Ειδοποιήσεις</Text>
              </>
            }
            extra
          >
            <MainTable />
          </GridCard>
        </Col>
      </Row>
      <Divider />
    </>
  );
};

export default Home;
