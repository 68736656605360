interface ITransformDateTimeService {
  dateEpochToGR(epochTime: number): string;
  timeEpochToGR(epochTime: number): string;
  dateTimeEpochToGr(epochTime: number): string;
}
export class TransformDateTimeService implements ITransformDateTimeService {
  dateEpochToGR = (epochTime: number): string => {
    const date = new Date(epochTime);
    return new Intl.DateTimeFormat('el-GR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).format(date);
  };

  timeEpochToGR = (epochTime: number): string => {
    const time = new Date(epochTime);
    return new Intl.DateTimeFormat('el-GR', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }).format(time);
  };

  dateTimeEpochToGr = (epochTime: number): string => {
    const time = new Date(epochTime);
    return new Intl.DateTimeFormat('el-GR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }).format(time);
  };
}
