import { FC, ReactNode, useContext, useState } from 'react';
import { Collapse, Grid, Select } from 'antd';
import { ClimateType } from '../../types/types';
import { getClimateType } from '../../shared/services';
import { useQuery } from 'react-query';
import { ClimateTypeContext } from '../../shared/context/ClimateTypeContext';
import { panel, selectStyle, arrowStyle } from './styles';
import ConditonalWrapper from '../ConditionalWrapper/ConditionalWrapper';
import { CaretDownOutlined } from '@ant-design/icons';

const ClimateTypeSelector: FC = () => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const { Panel } = Collapse;
  const METEOROLOGICAL = 'Meteorological';
  const ENVIRONMENTAL = 'Enviromental';
  const METEOROLOGICALLABEL = 'Μετεωρολογικά Δεδομένα';
  const ENVIRONMENTALLABEL = 'Περιβαλλοντικά Δεδομένα';
  const { climateTypeCategory, setClimateTypeCategory, setClimateType } =
    useContext(ClimateTypeContext);

  type AxiosResponse = {
    responseData: ClimateType[];
    statusCode: number;
  };

  const { data: climateTypeMeteo } = useQuery({
    queryKey: ['climateTypeMeteo'],
    queryFn: () => getClimateType(METEOROLOGICAL),
    select(data: AxiosResponse) {
      return data.responseData.map(obj => ({
        value: obj.climateTypeCategory,
        label: obj.name,
        type: obj.type
      }));
    }
  });

  const { data: climateTypeSigfox } = useQuery({
    queryKey: ['climateTypeSigfox'],
    queryFn: () => getClimateType(ENVIRONMENTAL),
    select(data: AxiosResponse) {
      return data.responseData.map(obj => ({
        label: obj.name,
        value: obj.climateTypeCategory,
        type: obj.type
      }));
    }
  });

  const selectOptions = [
    { label: METEOROLOGICALLABEL, options: climateTypeMeteo },
    { label: ENVIRONMENTALLABEL, options: climateTypeSigfox }
  ];
  return (
    <ConditonalWrapper
      condition={xs}
      wrapper={(children: ReactNode) => (
        <Collapse size="small">
          <Panel header="" key="1" style={panel}>
            {children}
          </Panel>
        </Collapse>
      )}
    >
      <>
        {climateTypeMeteo && climateTypeSigfox && (
          <Select
            size="large"
            bordered={false}
            suffixIcon={<CaretDownOutlined style={arrowStyle} />}
            defaultValue={climateTypeCategory}
            style={selectStyle}
            options={selectOptions}
            onChange={(value, event: any) => {
              setClimateType(event.type);
              setClimateTypeCategory(value);
            }}
          />
        )}
      </>
    </ConditonalWrapper>
  );
};
export default ClimateTypeSelector;
