import { request } from './axiosRequest';
import endPoints from '../shared/endPoints.json';

const BASEURLAPI =
  process.env.REACT_APP_ENVIROMENT === 'DEVELOPMENT'
    ? endPoints.BASEDEVURLAPI
    : endPoints.BASEURLAPI;

export const BASEURLALARMSERVICE =
  process.env.REACT_APP_ENVIROMENT === 'DEVELOPMENT'
    ? endPoints.BASEDEVURLALARMSERVICE
    : endPoints.BASEURLALARMSERVICE;

export const getAlarmLimits = (climateTypeCategory: string | undefined) =>
  request({
    baseURL: BASEURLALARMSERVICE,
    url: endPoints.ALARM.GETALARMLIMITS,
    method: 'GET',
    params: {
      climateTypeCategory: climateTypeCategory
    }
  });

export const getAlarmLimitsSigfox = (climateTypeCategory: string | undefined) =>
  request({
    baseURL: BASEURLALARMSERVICE,
    url: endPoints.ALARM.GETALARMLIMITSSIGFOX,
    method: 'GET',
    params: {
      climateTypeCategory: climateTypeCategory
    }
  });

export const getMeteoSensorDetailsByClimateTypeCategory = (
  code: string | undefined,
  climateTypeCategory: string | undefined
) =>
  request({
    baseURL: BASEURLAPI,
    url: endPoints.SENSOR.GETMETEOSENSORDETAILSMBYCLIMATETYPECATEGORY,
    method: 'GET',
    params: {
      UniqueCode: code,
      climateTypeCategory: climateTypeCategory
    }
  });

export const getSensorDetails = (code: string | undefined) =>
  request({
    baseURL: BASEURLAPI,
    url: endPoints.SENSOR.GETSENSORDETAILS,
    method: 'GET',
    params: {
      UniqueCode: code
    }
  });

export const getSigfoxSensorDetailsByClimateTypeCategory = (
  code: string | undefined,
  climateTypeCategory: string | undefined
) =>
  request({
    baseURL: BASEURLAPI,
    url: endPoints.SENSOR.GETSIGFOXSENSORDETAILSBYCLIMATETYPECATEGORY,
    method: 'GET',
    params: {
      UniqueCode: code,
      climateTypeCategory: climateTypeCategory
    }
  });

export const getSensorDetailsSigfox = (code: string | undefined) =>
  request({
    baseURL: BASEURLAPI,
    url: endPoints.SENSOR.GETSENSORDETAILSSIGFOX,
    method: 'GET',
    params: {
      UniqueCode: code
    }
  });

export const getAlarms = () =>
  request({
    baseURL: BASEURLALARMSERVICE,
    url: endPoints.ALARM.GETALARMS,
    method: 'GET'
  });

export const getClimateType = (climateType: string | undefined) =>
  request({
    baseURL: BASEURLAPI,
    url: endPoints.CLIMATETYPE.GETCLIMATETYPE,
    method: 'GET',
    params: {
      ClimateType: climateType
    }
  });

export const getClimateTypeLimits = (climateTypeCategory: string | undefined) =>
  request({
    baseURL: BASEURLAPI,
    url: endPoints.CLIMATETYPE.GETCLIMATETYPELIMITS,
    method: 'GET',
    params: {
      Type: climateTypeCategory
    }
  });

export const getSensorClimateTypeCategory = (climateTypeCategory: string) =>
  request({
    baseURL: BASEURLAPI,
    url: endPoints.SENSOR.GETSENSORCLIMATECATEGORY,
    method: 'GET',
    params: {
      ClimateTypeCategory: climateTypeCategory
    }
  });
