import { FC, useContext } from 'react';
import { Col, Row, Divider, Typography, Button, Space, Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ClimateTypeContext } from '../../shared/context/ClimateTypeContext';
import GridCard from '../../components/GridCard/GridCard';
import { pageTitle } from './styles';
import { baseStyle } from '../../shared/styles/baseStyles';
import StatisticsAqi from '../../components/Statistics/StatisticsAqi';
import SensorSettings from '../../components/SensorSettings/SensorSettings';
import { CloseCircleOutlined } from '@ant-design/icons';
import { exitIcon } from './styles';
import Statistics from '../../components/Statistics/Statistics';
const { Text } = Typography;
type Props = {
  dataType: string;
  closeLayout: () => void;
};
const ClimateTypeSensorDetails: FC<Props> = ({ dataType, closeLayout }) => {
  const { sensorType, climateTypeCategory, sensorId, stationName } =
    useContext(ClimateTypeContext);
  const navigate = useNavigate();
  return (
    <GridCard
      title={
        <Text style={baseStyle.subCardHeader}>Σταθμός : {stationName}</Text>
      }
      extra={<CloseCircleOutlined onClick={closeLayout} style={exitIcon} />}
    >
      <Row justify="center">
        <Button
          onClick={() => navigate(`/details/${sensorType}/sensor/${sensorId}`)}
          type="link"
        >
          Όλες οι μετρήσεις του Σταθμού
        </Button>
      </Row>
      <Space
        direction="vertical"
        size="middle"
        style={{ display: 'flex', margin: 8 }}
      >
        <GridCard
          title={
            <Text style={baseStyle.nestedSubCardHeader}>
              Στατιστική Απεικόνιση Ημερήσιων Μετρήσεων
            </Text>
          }
          extra={undefined}
        >
          {climateTypeCategory !== 'AQI' ? <Statistics /> : <StatisticsAqi />}
        </GridCard>
        <GridCard
          title={
            <Text style={baseStyle.nestedSubCardHeader}>
              Όρια Ειδοποιήσεων {dataType} Δεδομένων
            </Text>
          }
          extra={undefined}
        >
          <SensorSettings />
        </GridCard>
      </Space>
    </GridCard>
  );
};
export default ClimateTypeSensorDetails;
