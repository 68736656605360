import { Typography } from 'antd';

const DailyMeasurementsControl = () => {
  const { Text } = Typography;

  return (
    <div className="leaflet-bottom leaflet-right">
      <div className="leaflet-control">
        <Text italic style={{ color: 'white' }}>
          Ημερήσιες μετρήσεις
        </Text>
      </div>
    </div>
  );
};
export default DailyMeasurementsControl;
