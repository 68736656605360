import { FC, useContext } from 'react';
import {
  SensorDetails,
  SensorDetailsByClimateTypeCategory
} from '../../types/types';
import LineChart from './LineChart';
import { useLocation } from 'react-router-dom';
import { TransformDateTimeService } from '../../services/TransformDateTimeService';
import { useQuery } from 'react-query';
import LoadingSpin from '../LoadingSpin/LoadingSpin';
import { ClimateTypeContext } from '../../shared/context/ClimateTypeContext';
import ViewTable from '../ViewTable/ViewTable';
import { getSensorDetailsSigfox } from '../../shared/services';

const StatisticsSigfoxAll: FC = () => {
  const { sensorId } = useContext(ClimateTypeContext);
  const { pathname } = useLocation();
  let lineChartData;
  const SIGFOXSELECTEDITEMS = {
    'Θερμοκρασία (°C)': true,
    'Υγρασία (%)': pathname === '/',
    'Αιωρ. Σωματίδια (PM2.5) (ug/m3)': pathname === '/',
    'Αιωρ. Σωματίδια (PM10) (ug/m3)': pathname === '/',
    'Διοξείδιο του Αζώτου (NO2) (ppm ή ppb)': pathname === '/',
    'Διοξείδιο του Θείου (SO2) (ppm ή ppb)': pathname === '/',
    'Όζον (O3) (ppm ή ppb)': pathname === '/'
  };

  const { data: sensorDetails, isLoading } = useQuery({
    enabled: true,
    queryKey: ['sensorDetailsSigfox', sensorId],
    queryFn: () => getSensorDetailsSigfox(sensorId)
  });

  const transformDateTime = new TransformDateTimeService();

  if (isLoading) return <LoadingSpin />;

  const descriptionValues =
    sensorDetails?.responseData.dailySigfoxSensorDetails.map(
      (y: SensorDetailsByClimateTypeCategory) => {
        return {
          min: y?.minimum,
          avg: y?.average,
          max: y?.maximum,
          label: y?.climateName
        };
      }
    );

  lineChartData = sensorDetails?.responseData.dailySigfoxSensorDetails.map(
    (y: SensorDetailsByClimateTypeCategory) => {
      return y.sensorDetails.map((x: SensorDetails) => {
        return {
          value: x.value,
          time: transformDateTime.timeEpochToGR(x?.dateTime),
          date: transformDateTime.dateEpochToGR(x?.dateTime),
          label: y.climateName
        };
      });
    }
  );
  if (lineChartData.length !== 0) {
    lineChartData = [
      ...lineChartData[7],
      ...lineChartData[6],
      ...lineChartData[4],
      ...lineChartData[3],
      ...lineChartData[2],
      ...lineChartData[1],
      ...lineChartData[0]
    ];
  }

  // ...lineChartData[5]
  // ...lineChartData[8]
  // ...lineChartData[9]

  return descriptionValues.length !== 0 ? (
    <LineChart
      descriptionValues={descriptionValues}
      lineChartData={lineChartData}
      selectedItems={SIGFOXSELECTEDITEMS}
    />
  ) : (
    <ViewTable columns={[]} data={[]} footerTitle={undefined} />
  );
};

export default StatisticsSigfoxAll;
