import React from 'react';
import { Space, Spin } from 'antd';
import { spin } from './styles';

const LoadingSpin: React.FC = () => (
  <Space direction="vertical" style={spin}>
    <Space>
      <Spin tip="Loading..." size="large"></Spin>
    </Space>
  </Space>
);

export default LoadingSpin;
