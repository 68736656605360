import { FC, useContext, useEffect, useState } from 'react';
import { Popup, Tooltip, CircleMarker } from 'react-leaflet';
import { useQuery } from 'react-query';
import { getSensorClimateTypeCategory } from '../../shared/services';
import 'leaflet/dist/leaflet.css';
import { Sensor } from '../../types/types';
import PopUpDetails from '../PopUpDetails/PopUpDetails';
import MapView from '../MapView/MapView';
import { Spin } from 'antd';
import './MainMap.css';
import { ClimateTypeContext } from '../../shared/context/ClimateTypeContext';
import LimitsColorLegendControl from '../MapControls/LimitsColorLegendControl';
import DailyMeasurementsControl from '../MapControls/DailyMeasurementControl';

type Props = {
  openLayout: () => void;
  collapsed: boolean;
};

const MainMap: FC<Props> = ({ openLayout, collapsed }: Props) => {
  const { climateTypeCategory, setSensorId, setSensorType, setStationName } =
    useContext(ClimateTypeContext);
  const position = [37.996737, 23.677221];
  const [map, setMap] = useState<any>(null);

  const {
    data: sensorClimateTypeCategory,
    isLoading: sensorClimateTypeCategoryLoading
  } = useQuery({
    enabled: !!climateTypeCategory,
    queryKey: ['sensorClimateTypeCategory', climateTypeCategory],
    queryFn: () => getSensorClimateTypeCategory(climateTypeCategory)
  });

  const flyToMarker = (lat: number, long: number) => {
    if (map) {
      map.flyTo([lat, long], 15);
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 2500);
    }
  };

  useEffect(() => {
    if (!collapsed && map) {
      map.setView(position, 11);
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 2500);
    }
  }, [collapsed]);

  return (
    <>
      <MapView position={position} height={'70vh'} setMap={setMap}>
        <LimitsColorLegendControl />
        <DailyMeasurementsControl />
        {sensorClimateTypeCategory?.responseData.map((sensor: Sensor) => (
          <CircleMarker
            radius={14}
            color={sensor?.climateColor}
            fill
            fillColor={sensor?.climateColor}
            key={sensor.uniqueCode}
            center={[sensor.latitude, sensor.longitude]}
            eventHandlers={{
              mouseover: e => !collapsed && e.target.openPopup(),
              mouseout: e => !collapsed && e.target.closePopup(),
              click: e => {
                e.target.openPopup();
                setSensorId(sensor.uniqueCode);
                setSensorType(sensor.sensorType.toLowerCase());
                setStationName(sensor.stationName);
                flyToMarker(sensor.latitude, sensor.longitude);
                openLayout();
              }
            }}
          >
            <Popup>
              {sensorClimateTypeCategoryLoading ? (
                <Spin size="small" />
              ) : (
                <PopUpDetails sensor={sensor} />
              )}
            </Popup>
            <Tooltip
              className={`leaflet-tooltip-${sensor?.climateColor?.substring(
                1
              )} leaflet-tooltip-${
                sensor?.climateValue > 999 ? 'small' : 'large'
              }`}
              direction="center"
              permanent
            >
              {sensor?.climateValue !== null && sensor?.climateValue.toString()}
            </Tooltip>
          </CircleMarker>
        ))}
      </MapView>
    </>
  );
};

export default MainMap;
