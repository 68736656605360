/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import {
  SensorDetails,
  SensorDetailsByClimateTypeCategory
} from '../../types/types';
import LineChart from './LineChart';
import { TransformDateTimeService } from '../../services/TransformDateTimeService';
import LoadingSpin from '../LoadingSpin/LoadingSpin';
import { ClimateTypeContext } from '../../shared/context/ClimateTypeContext';
import ViewTable from '../ViewTable/ViewTable';
import { useQuery } from 'react-query';
import { getSensorDetails } from '../../shared/services';

const StatisticsMeteoAll: FC = () => {
  const { sensorId } = useContext(ClimateTypeContext);
  const { pathname } = useLocation();

  let lineChartData;
  const METEOSELECTEDITEMS = {
    'Θερμοκρασία (°C)': true,
    'Υγρασία (%)': pathname === '/',
    'Ταχύτητα ανέμου (χλμ/ώρα)': pathname === '/',
    'Ριπή ανέμου (χλμ/ώρα)': pathname === '/',
    'Βροχή από αρχή ημέρας (mm)': pathname === '/'
  };

  const { data: sensorDetails, isLoading } = useQuery({
    enabled: true,
    queryKey: ['sensorDetails', sensorId],
    queryFn: () => getSensorDetails(sensorId)
  });
  const transformDateTime = new TransformDateTimeService();

  if (isLoading) return <LoadingSpin />;

  const descriptionValues = sensorDetails?.responseData.dailySensorDetails.map(
    (y: SensorDetailsByClimateTypeCategory) => {
      return {
        min: y?.minimum,
        avg: y?.average,
        max: y?.maximum,
        label: y?.climateName
      };
    }
  );

  lineChartData = sensorDetails?.responseData.dailySensorDetails.map(
    (y: SensorDetailsByClimateTypeCategory) => {
      return y.sensorDetails.map((x: SensorDetails) => {
        return {
          value: x.value,
          time: transformDateTime.timeEpochToGR(x?.dateTime),
          date: transformDateTime.dateEpochToGR(x?.dateTime),
          label: y.climateName
        };
      });
    }
  );

  if (lineChartData.length !== 0) {
    lineChartData = [
      ...lineChartData[4],
      ...lineChartData[3],
      ...lineChartData[2],
      ...lineChartData[1],
      ...lineChartData[0]
    ];
  }

  return descriptionValues.length !== 0 ? (
    <LineChart
      descriptionValues={descriptionValues}
      lineChartData={lineChartData}
      selectedItems={METEOSELECTEDITEMS}
    />
  ) : (
    <ViewTable columns={[]} data={[]} footerTitle={undefined} />
  );
};

export default StatisticsMeteoAll;
