export const radioGroup = {
  display: 'flex',
  justifyContent: 'center'
};

export const description = {
  justifyContent: 'center',
  display: 'flex'
};

export const descriptionsItem = {
  fontSize: 18,
  fontWeight: 'bold'
};
