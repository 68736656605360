import { FC, useContext } from 'react';
import {
  SensorDetails,
  SensorDetailsByClimateTypeCategory
} from '../../types/types';
import LineChart from './LineChart';
import { useLocation } from 'react-router-dom';
import { TransformDateTimeService } from '../../services/TransformDateTimeService';
import { useQueryClient } from 'react-query';
import LoadingSpin from '../LoadingSpin/LoadingSpin';
import { ClimateTypeContext } from '../../shared/context/ClimateTypeContext';
import ViewTable from '../ViewTable/ViewTable';

type AxiosResponse = {
  responseData: SensorDetailsByClimateTypeCategory;
  statusCode: number;
};

const Statistics: FC = () => {
  const { sensorId, climateTypeCategory, sensorType } =
    useContext(ClimateTypeContext);

  const { pathname } = useLocation();
  const QUERYKEY =
    sensorType === 'meteo'
      ? 'meteoSensorDetailsByClimateTypeCategory'
      : 'sigfoxSensorDetailsByClimateTypeCategory';

  const SELECTEDITEMS =
    sensorType === 'meteo'
      ? {
          'Θερμοκρασία (°C)': true,
          'Υγρασία (%)': pathname === '/',
          'Ταχύτητα ανέμου (χλμ/ώρα)': pathname === '/',
          'Ριπή ανέμου (χλμ/ώρα)': pathname === '/',
          'Βροχή από αρχή ημέρας (mm)': pathname === '/'
        }
      : {
          'Θερμοκρασία (°C)': true,
          'Υγρασία (%)': pathname === '/',
          'Αιωρ. Σωματίδια (PM2.5) (ug/m3)': pathname === '/',
          'Αιωρ. Σωματίδια (PM10) (ug/m3)': pathname === '/',
          'Μονοξείδιο του Άνθρακα(CO)(ppm ή ppb)': pathname === '/',
          'Διοξείδιο του Αζώτου (NO2) (ppm ή ppb)': pathname === '/',
          'Διοξείδιο του Θείου (SO2) (ppm ή ppb)': pathname === '/',
          'Όζον (O3) (ppm ή ppb)': pathname === '/'
        };

  const transformDateTime = new TransformDateTimeService();
  const queryClient = useQueryClient();
  const cachedData: AxiosResponse | undefined = queryClient.getQueryData([
    QUERYKEY,
    sensorId,
    climateTypeCategory
  ]);
  if (!cachedData) return <LoadingSpin />;

  const data = {
    min: cachedData.responseData?.minimum,
    avg: cachedData.responseData?.average,
    max: cachedData.responseData?.maximum,
    label: cachedData.responseData?.climateName
  };

  const descriptionValues = [data];
  const lineChartData = cachedData.responseData.sensorDetails?.map(
    (y: SensorDetails) => {
      return {
        value: y.value,
        time: transformDateTime.timeEpochToGR(y?.dateTime),
        date: transformDateTime.dateEpochToGR(y?.dateTime),
        label: cachedData.responseData?.climateName
      };
    }
  );

  return cachedData?.responseData?.sensorDetails?.length !== 0 ? (
    <LineChart
      descriptionValues={descriptionValues}
      lineChartData={lineChartData}
      selectedItems={SELECTEDITEMS}
    />
  ) : (
    <ViewTable columns={[]} data={[]} footerTitle={undefined} />
  );
};

export default Statistics;
