import { FC, useContext } from 'react';
import './App.css';
import Router from './components/Router/Router';
import { BrowserRouter } from 'react-router-dom';
import { SignalRContext } from '../src/shared/context/SignalRContext';
import { notification, Layout } from 'antd';
import { baseStyle } from './shared/styles/baseStyles';
import Header from './components/Header/Header';
import { AlertOutlined } from '@ant-design/icons';
import Footer from './components/Footer/Footer';


const App: FC = () => {
  const { receivedAlarm } = useContext(SignalRContext);
  if (receivedAlarm) {
    notification.open({
      message: `Ειδοποίηση ${
        receivedAlarm.severity === 'High' ? 'Υψηλού' : 'Μετρίου'
      } Κινδύνου`,
      description: `${receivedAlarm.type} : ${receivedAlarm.value}`,
      icon: <AlertOutlined style={{ color: 'red' }} />
    });
  }

  return (
    <BrowserRouter>
      <Layout style={baseStyle.layoutStyle}>
        <Header />
        <Router />
        <Footer />
      </Layout>
    </BrowserRouter>
  );
};
export default App;
