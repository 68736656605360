import { FC, ReactElement } from 'react';

type ConditonalWrapperProps = {
  children: ReactElement;
  condition?: boolean;
  wrapper: (children: ReactElement) => JSX.Element;
};

const ConditonalWrapper: FC<ConditonalWrapperProps> = ({
  condition,
  wrapper,
  children
}) => (condition ? wrapper(children) : children);

export default ConditonalWrapper
