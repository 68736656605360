import React, { FC } from 'react';
import { Table } from 'antd';
type Column = {
  title: string | JSX.Element;
  dataIndex: string;
  key: string;
};
type Props = {
  data: { [key: string]: string | number }[];
  columns: Column[];
  footerTitle: undefined | JSX.Element;
};

const ViewTable: FC<Props> = ({ data, columns, footerTitle }: Props) => {
  return (
    <Table
      footer={() => footerTitle}
      rowKey={() => Math.random()}
      columns={columns}
      scroll={{ x: 'max-content' }}
      size="middle"
      dataSource={data}
    />
  );
};

export default ViewTable;
