import { FC, useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Control, DomUtil } from 'leaflet';
import { useLeafletContext } from '@react-leaflet/core';
import { Button, Modal, Image, Typography } from 'antd';
import { ClimateLimit } from '../../types/types';
import { useQuery } from 'react-query';
import { getClimateTypeLimits } from '../../shared/services';
import { ClimateTypeContext } from '../../shared/context/ClimateTypeContext';
import environmentalDataLegend from '../../../src/assets/environmentalDataLegend.png';
import './styles.css';

const LimitsColorLegendControl: FC = () => {
  const { Text } = Typography;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { climateTypeCategory } = useContext(ClimateTypeContext);
  const context = useLeafletContext();
  const LEGENDEXPLAINED = 'Επεξήγηση Υπομνήματος';
  const AQISOURCELABEL = 'Πηγή: ';
  const AQISOURCE =
    'Κατηγοριοποίηση βάσει “U.S Environmental Protection Agency”';
  const climateCategoriesWithLegend = ['SO2', 'Pm2_5', 'Pm10', 'O3', 'NO2'];

  const LEGENDSOURCE =
    '“European Commission’s Directorate General for Environment” και “European Environment Agency”';

  const { data: climateLimits } = useQuery({
    queryKey: ['climateLimits', climateTypeCategory],
    queryFn: () => getClimateTypeLimits(climateTypeCategory)
  });

  let limitColors: JSX.Element;
  limitColors = climateLimits?.responseData?.limits?.map(
    (limit: ClimateLimit, index: number) => (
      <div key={index}>
        <i style={{ background: limit.color }}></i>
        {limit.description}
      </div>
    )
  );
  if (climateTypeCategory == 'AQI') {
    limitColors = (
      <>
        <>{limitColors}</>
        <Text style={{ fontStyle: 'italic' }}>{AQISOURCE}</Text>
      </>
    );
  }

  if (
    climateLimits?.responseData?.hasDescription &&
    climateCategoriesWithLegend.includes(climateTypeCategory)
  ) {
    limitColors = (
      <>
        <>{limitColors}</>
        <Button type="link" onClick={() => setIsModalOpen(true)}>
          {LEGENDEXPLAINED}
        </Button>
      </>
    );
  }
  useEffect(() => {
    const colorLegendControl = new Control({ position: 'bottomleft' });
    colorLegendControl.onAdd = () => {
      const colorLegend = DomUtil.create('div', 'legend info');
      const root = ReactDOM.createRoot(colorLegend);
      root.render(limitColors);
      return colorLegend;
    };
    if (climateLimits) colorLegendControl.addTo(context.map);

    return () => {
      if (colorLegendControl) {
        colorLegendControl.remove();
      }
    };
  }, [climateLimits]);

  return (
    <Modal
      width={650}
      title={LEGENDEXPLAINED}
      centered
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
    >
      <Image src={environmentalDataLegend} />
      <Text> Κατηγοριοποίηση βάσει: </Text>
      <Text strong italic>
        {LEGENDSOURCE}
      </Text>
    </Modal>
  );
};
export default LimitsColorLegendControl;
