import { FC, useEffect, useState, createContext } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Alarm } from '../../types/types';
import endPoints from '../endPoints.json';
import { BASEURLALARMSERVICE } from '../services';

type SignalRContextType = {
  receivedAlarm: Alarm | undefined;
};
type Props = {
  children: JSX.Element;
};
export const SignalRContext = createContext<SignalRContextType>({
  receivedAlarm: undefined
});

const SignalRContextProvider: FC<Props> = ({ children }) => {
  const [receivedAlarm, setReceivedAlarm] = useState<Alarm>();
  useEffect(() => {
    // Establish connection to SignalR hub server
    const connection = new HubConnectionBuilder()

      .withUrl(`${BASEURLALARMSERVICE}${endPoints.ALARM.SIGNALRHUB}`)
      .withAutomaticReconnect()
      .build();

    connection.start().then(() => console.log('Connection started!'));
    connection.on('ReceiveAlert', (alarm: Alarm) => {
      setReceivedAlarm(alarm);
    });
    return () => {
      connection.stop().catch(err => console.error(err));
    };
  }, []);
  return (
    <SignalRContext.Provider
      value={{
        receivedAlarm: receivedAlarm
      }}
    >
      {children}
    </SignalRContext.Provider>
  );
};
export default SignalRContextProvider;
