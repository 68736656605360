/* eslint-disable react/prop-types */
import L from 'leaflet';
import { createControlComponent } from '@react-leaflet/core';
import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';
import { MapContainer, TileLayer } from 'react-leaflet';

const FullscreenControl = createControlComponent(props =>
  L.control.fullscreen(props)
);

const MapView = ({ position, height, setMap, children }) => {
  const zoomLevel = 11;
  const maxZoom = 14;
  const minZoom = 9;

  return (
    <MapContainer
      ref={setMap}
      attributionControl={false}
      center={[position[0], position[1]]}
      zoom={zoomLevel}
      maxZoom={maxZoom}
      minZoom={minZoom}
      scrollWheelZoom={true}
      style={{
        height: height
      }}
    >
      <FullscreenControl />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
      />
      {children}
    </MapContainer>
  );
};

export default MapView;
