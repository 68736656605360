import { FC, useContext } from 'react';
import { useQuery } from 'react-query';
import { ClimateTypeContext } from '../../shared/context/ClimateTypeContext';
import { SensorDetails } from '../../types/types';
import LineChart from './LineChart';
import { TransformDateTimeService } from '../../services/TransformDateTimeService';
import LoadingSpin from '../LoadingSpin/LoadingSpin';
import ViewTable from '../ViewTable/ViewTable';
import { getSigfoxSensorDetailsByClimateTypeCategory } from '../../shared/services';

const StatisticsAqi: FC = () => {
  const transformDateTime = new TransformDateTimeService();
  const { sensorId } = useContext(ClimateTypeContext);

  const { data: sigfoxSensorDetailsByClimateTypeCategory, isLoading } =
    useQuery({
      enabled: true,
      queryKey: ['sigfoxSensorDetailsByClimateTypeCategory', sensorId, 'AQI'],
      queryFn: () =>
        getSigfoxSensorDetailsByClimateTypeCategory(sensorId, 'AQI')
    });

  if (isLoading) return <LoadingSpin />;

  const AQISELECTEDITEMS = {
    'Δείκτης Ποιότητας Αέρα (AQI)': true
  };
  const aqiData = {
    min: sigfoxSensorDetailsByClimateTypeCategory?.responseData.minimum,
    avg: sigfoxSensorDetailsByClimateTypeCategory?.responseData.average,
    max: sigfoxSensorDetailsByClimateTypeCategory?.responseData.maximum,
    label: sigfoxSensorDetailsByClimateTypeCategory?.responseData.climateName,
    lineChartData:
      sigfoxSensorDetailsByClimateTypeCategory?.responseData.sensorDetails.map(
        (y: SensorDetails) => {
          return {
            value: y.value,
            time: transformDateTime.timeEpochToGR(y.dateTime),
            date: transformDateTime.dateEpochToGR(y.dateTime),
            label:
              sigfoxSensorDetailsByClimateTypeCategory?.responseData.climateName
          };
        }
      )
  };

  return aqiData.lineChartData.length !== 0 ? (
    <LineChart
      descriptionValues={[aqiData]}
      lineChartData={aqiData.lineChartData}
      selectedItems={AQISELECTEDITEMS}
    />
  ) : (
    <ViewTable columns={[]} data={[]} footerTitle={undefined} />
  );
};
export default StatisticsAqi;
