import { FC, useContext } from 'react';
import { useQuery } from 'react-query';
import { getAlarmLimits, getAlarmLimitsSigfox } from '../../shared/services';
import { lowSeverityIcon, highSeverityIcon } from '../../shared/Icons/Icons';
import { Limit } from '../../types/types';
import ViewTable from '../ViewTable/ViewTable';
import LoadingSpin from '../LoadingSpin/LoadingSpin';
import { ClimateTypeContext } from '../../shared/context/ClimateTypeContext';
import { useLocation } from 'react-router-dom';
import { Col, Row, Typography } from 'antd';
const SensorSettings: FC = () => {
  const { sensorType, climateTypeCategory, climateType } =
    useContext(ClimateTypeContext);
  const { pathname } = useLocation();
  const { Text } = Typography;
  const footerTitle =
    climateType === 'Meteorological' && pathname === '/' ? (
      <>
        <Text>Πηγή:</Text>
        <Text strong italic>
          {' '}
          Εθνικό Αστεροσκοπείο Αθηνών-Meteo
        </Text>
      </>
    ) : climateType === 'Enviromental' && pathname === '/' ? (
      <>
        <Text>Κατηγοριοποίηση βάσει:</Text>
        <Text strong italic>
          {' '}
          U.S Environmental Protection Agency
        </Text>
      </>
    ) : (
      <Row>
        <Col>
          <Text strong>Πηγή Μετεωρολογικών δεδομένων: </Text>
          <Text italic>“Εθνικό Αστεροσκοπείο Αθηνών-Meteo”</Text>
          <Row>
            <Text strong>Κατηγοριοποίηση Περιβαλλοντικών δεδομένων: </Text>
            <Text italic> “U.S Environmental Protection Agency”</Text>
          </Row>
        </Col>
      </Row>
    );
  const { data: alarmLimits, status: alarmLimitsStatus } = useQuery({
    enabled: sensorType === 'meteo',
    queryKey: ['alarmLimits', climateTypeCategory],
    queryFn: () =>
      getAlarmLimits(pathname === '/' ? climateTypeCategory : undefined)
  });
  const { data: alarmLimitsSigfox, status: alarmLimitsStatusSigfox } = useQuery(
    {
      enabled: sensorType === 'sigfox',
      queryKey: ['alarmLimitsSigfox', climateTypeCategory],
      queryFn: () =>
        getAlarmLimitsSigfox(pathname === '/' ? climateTypeCategory : undefined)
    }
  );
  if (alarmLimitsStatus === 'loading' || alarmLimitsStatusSigfox === 'loading')
    return <LoadingSpin />;
  const limits = sensorType === 'meteo' ? alarmLimits : alarmLimitsSigfox;
  const columns = [
    {
      title: 'Τύπος Δεδομένων',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      render: (type: string) => {
        return type;
      }
    },
    {
      title: (
        <>
          {'Μέτριου Κινδύνου '}
          {lowSeverityIcon}
        </>
      ),
      dataIndex: 'limits',
      key: 'lowLimits',
      align: 'center',
      render: (limits: Limit[]) => {
        return (
          <>
            {limits.map((limit: Limit) => {
              if (limit.severity === 'Low') {
                const limitText =
                  limit.rightLimit < 0
                    ? `έως ${limit.rightLimit}`
                    : `από ${limit.leftLimit} έως ${limit.rightLimit}`;
                return <>{limitText}</>;
              }
              return null;
            })}
          </>
        );
      }
    },
    {
      title: (
        <>
          {'Υψηλού Κινδύνου '}
          {highSeverityIcon}
        </>
      ),
      dataIndex: 'limits',
      key: 'highLimits',
      align: 'center',
      render: (limits: Limit[]) => {
        return (
          <>
            {limits.map((limit: Limit) => {
              if (limit.severity === 'High') {
                const limitText =
                  limit.leftLimit < 0
                    ? `κάτω από ${limit.rightLimit}`
                    : `πάνω από ${limit.leftLimit}`;
                return <>{limitText}</>;
              }
              return null;
            })}
          </>
        );
      }
    }
  ];
  return (
    <ViewTable
      columns={columns}
      data={limits?.responseData}
      footerTitle={footerTitle}
    />
  );
};
export default SensorSettings;
