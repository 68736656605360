// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  .legend {
    text-align: left;
    line-height: 18px;
    color: #555;
  }
  .legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.8;
  }`, "",{"version":3,"sources":["webpack://./src/components/MapControls/styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,4CAA4C;IAC5C,iBAAiB;IACjB,oCAAoC;IACpC,uCAAuC;IACvC,kBAAkB;EACpB;EACA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;EACb;EACA;IACE,WAAW;IACX,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,YAAY;EACd","sourcesContent":[".info {\n    padding: 6px 8px;\n    font: 14px/16px Arial, Helvetica, sans-serif;\n    background: white;\n    background: rgba(255, 255, 255, 0.8);\n    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);\n    border-radius: 5px;\n  }\n  .legend {\n    text-align: left;\n    line-height: 18px;\n    color: #555;\n  }\n  .legend i {\n    width: 18px;\n    height: 18px;\n    float: left;\n    margin-right: 8px;\n    opacity: 0.8;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
