import { FC, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Typography } from 'antd';
import { getAlarms } from '../../shared/services';
import ViewTable from '../ViewTable/ViewTable';
import { TransformDateTimeService } from '../../services/TransformDateTimeService';
import { Alarm } from '../../types/types';
import { lowSeverityIcon, highSeverityIcon } from '../../shared/Icons/Icons';
import LoadingSpin from '../LoadingSpin/LoadingSpin';
import { SignalRContext } from '../../shared/context/SignalRContext';

const MainTable: FC = () => {
  const [alarms, setAlarms] = useState<Alarm[]>([]);
  const { receivedAlarm } = useContext(SignalRContext);
  const transformDateTime = new TransformDateTimeService();
  const { Text } = Typography;
  const { status: alarmStatus } = useQuery({
    queryKey: ['alarms'],
    queryFn: getAlarms,
    onSuccess(data) {
      setAlarms(data.responseData);
    }
  });
  const FOOTERTITLE = (
    <Text strong italic>
      Ημερήσιες Ειδοποιήσεις
    </Text>
  );
  useEffect(() => {
    if (receivedAlarm) setAlarms([receivedAlarm, ...alarms]);
  }, [receivedAlarm]);
  if (alarmStatus === 'loading') return <LoadingSpin />;
  const columns = [
    {
      title: 'Ημερομηνία',
      dataIndex: 'dateTime',
      key: 'dateTime',
      align: 'center',
      render: (dateTime: number) =>
        transformDateTime.dateTimeEpochToGr(dateTime)
    },
    {
      title: 'Τύπος Ειδοποίησης',
      dataIndex: 'type',
      key: 'type',
      align: 'center'
    },
    {
      title: 'Τιμή Ειδοποίησης',
      dataIndex: 'value',
      key: 'value',
      align: 'center',
      render: (value: number, alarm: Alarm) => {
        return (
          <>
            {value}{' '}
            {'Low' === alarm.severity ? lowSeverityIcon : highSeverityIcon}
          </>
        );
      }
    },
    {
      title: 'Σταθμός',
      dataIndex: 'stationName',
      key: 'stationName',
      align: 'center'
    },
    {
      title: 'Περιοχή',
      dataIndex: 'stationName',
      key: 'station',
      align: 'center'
    }
  ];

  return (
    <ViewTable columns={columns} data={alarms} footerTitle={FOOTERTITLE} />
  );
};

export default MainTable;
