import { FC, useContext } from 'react';
import { Col, Row, Divider, Typography } from 'antd';
import { ClimateTypeContext } from '../../shared/context/ClimateTypeContext';
import GridCard from '../../components/GridCard/GridCard';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { pageTitle, titleButton } from './styles';
import { baseStyle } from '../../shared/styles/baseStyles';
import { useNavigate } from 'react-router-dom';
import StatisticsMeteoAll from '../../components/Statistics/StatisticsMeteoAll';
import StatisticsSigfoxAll from '../../components/Statistics/StatisticsSigfoxAll';
import StatisticsAqi from '../../components/Statistics/StatisticsAqi';
import SensorSettings from '../../components/SensorSettings/SensorSettings';

const { Text } = Typography;

type Props = {
  sensorName: string;
};

const SensorDetails: FC<Props> = ({ sensorName }) => {
  const navigate = useNavigate();
  const { sensorType } = useContext(ClimateTypeContext);

  return (
    <>
      <Divider style={baseStyle?.pageHeader}>
        Όλες οι μετρήσεις του Σταθμού
      </Divider>
      <Divider style={pageTitle}>
        <>
          <ArrowLeftOutlined style={titleButton} onClick={() => navigate(-1)} />
          <Text style={baseStyle?.pageHeader}>Σταθμός: {sensorName}</Text>
        </>
      </Divider>
      <Row justify="center">
        <Col xs={22} md={20}>
          <GridCard
            title={
              <Text style={baseStyle?.subCardHeader}>
                Στατιστική Απεικόνιση Ημερήσιων Μετρήσεων
              </Text>
            }
            extra
          >
            {sensorType === 'meteo' ? (
              <StatisticsMeteoAll />
            ) : (
              <StatisticsSigfoxAll />
            )}
          </GridCard>
          {sensorType === 'sigfox' && (
            <>
              <Divider />
              <GridCard
                title={
                  <Text style={baseStyle.subCardHeader}>
                    Στατιστική Απεικόνιση Δείκτη Ποιότητας Αέρα (AQI)
                  </Text>
                }
                extra
              >
                <StatisticsAqi />
              </GridCard>
            </>
          )}
        </Col>
      </Row>
      <Divider />
      <Row align="middle" justify="center">
        <Col xs={22} md={14}>
          <GridCard
            title={
              <Text style={baseStyle.subCardHeader}>
                Όρια Ειδοποιήσεων Δεδομένων
              </Text>
            }
            extra
          >
            <SensorSettings />
          </GridCard>
        </Col>
      </Row>
      <Divider />
    </>
  );
};

export default SensorDetails;
