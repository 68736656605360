import axios, { isAxiosError } from 'axios';

const client = (() => {
  return axios.create();
})();

export const request = async ({ ...options }) => {
  try {
    const result = await client(options);
    return {
      responseData: result.data,
      statusCode: result.status
    };
  } catch (error) {
    if (isAxiosError(error)) {
      return Promise.reject(error);
    } else {
      throw new Error('different error than axios');
    }
  }
};
