export const headCard = {
  textAlign: 'center' as const,
  color: 'green'
};

export const bodyCard = {
  padding: '0'
};

export const iconCard = {
  marginLeft: 5,
  fontSize: '125%'
};
