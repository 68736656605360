export const headerStyleLg: React.CSSProperties = {
  textAlign: 'center',
  zIndex: 9999,
  color: 'black',
  height: 131,
  fontSize: 25,
  lineHeight: '64px',
  backgroundColor: 'white',
  position: 'sticky',
  top: 0
};
export const headerStyleXs: React.CSSProperties = {
  textAlign: 'center',
  zIndex: 9999,
  color: 'black',
  height: 300,
  fontSize: 25,
  lineHeight: '64px',
  backgroundColor: 'white',
  position: 'sticky',
  top: 0
};
export const betaVersionStyle: React.CSSProperties = {
  color: 'black',
  fontSize: 15,
  lineHeight: '50px',
  animation: 'slideText 20s linear infinite',
  whiteSpace: 'nowrap'
};

export const imageStyle: React.CSSProperties = {
  marginTop: 12
};

export const textStyleMd: React.CSSProperties = {
  fontSize: 20,
  lineHeight: 'normal'
};

export const textStyleLg: React.CSSProperties = {
  fontSize: 25
};
