import React from 'react';
import { Button, Result } from 'antd';

type Props = {
  subTitle: string;
};
const ErrorPage: React.FC<Props> = ({ subTitle }) => {
  return (
    <Result
      status="500"
      title="Oops!"
      subTitle={subTitle}
      extra={
        <Button
          type="primary"
          onClick={() => {
            window.location.href = `${window.location.href}`;
          }}
        >
          Προσπάθησε Ξανά
        </Button>
      }
    />
  );
};

export default ErrorPage;
