import { useLocation } from 'react-router-dom';
import { FC, useContext } from 'react';
import { ClimateTypeContext } from '../../shared/context/ClimateTypeContext';
import { useQuery } from 'react-query';
import {
  getMeteoSensorDetailsByClimateTypeCategory,
  getSigfoxSensorDetailsByClimateTypeCategory
} from '../../shared/services';
import { SensorDetailsByClimateTypeCategory } from '../../types/types';
import SensorDetails from './SensorDetails';
import ClimateTypeSensorDetails from './ClimateTypeSensorDetails';
import LoadingSpin from '../../components/LoadingSpin/LoadingSpin';

type Props = {
  closeLayout: () => void;
};

const SensorDetailsContainer: FC<Props> = ({ closeLayout }) => {
  const { sensorType, sensorId, climateType, climateTypeCategory } =
    useContext(ClimateTypeContext);
  const dataType =
    climateType === 'Meteorological' ? 'Μετεωρολογικών' : 'Περιβαλλοντικών';
  const { pathname } = useLocation();

  const { data: meteoSensorDetailsByClimateTypeCategory } = useQuery({
    enabled: sensorType === 'meteo',
    queryKey: [
      'meteoSensorDetailsByClimateTypeCategory',
      sensorId,
      climateTypeCategory
    ],
    queryFn: () =>
      getMeteoSensorDetailsByClimateTypeCategory(sensorId, climateTypeCategory)
  });

  const { data: sigfoxSensorDetailsByClimateTypeCategory } = useQuery({
    enabled: sensorType === 'sigfox',
    queryKey: [
      'sigfoxSensorDetailsByClimateTypeCategory',
      sensorId,
      climateTypeCategory
    ],
    queryFn: () =>
      getSigfoxSensorDetailsByClimateTypeCategory(sensorId, climateTypeCategory)
  });

  const details: SensorDetailsByClimateTypeCategory =
    sensorType === 'meteo'
      ? meteoSensorDetailsByClimateTypeCategory?.responseData
      : sigfoxSensorDetailsByClimateTypeCategory?.responseData;

  if (!details) return <LoadingSpin />;
  return pathname === '/' ? (
    <ClimateTypeSensorDetails dataType={dataType} closeLayout={closeLayout} />
  ) : (
    <SensorDetails sensorName={details?.stationName} />
  );
};

export default SensorDetailsContainer;
