export const row = {
  fontWeight: 'bold',
  justifyContent: 'center',
  fontSize: '14px'
};

export const noDataRow = {
  justifyContent: 'center'
};
export const paragraph = {
  margin: 0
};
export const label = {
  fontSize: 13
};
