export const baseStyle = {
  layoutStyle: { minHeight: '100vh', overflow: 'hidden' },
  pageHeader: { fontSize: 25 },
  mainCardHeader: { fontSize: 22 },
  subCardHeader: { fontSize: 20 },
  nestedSubCardHeader: { fontSize: 18 }
};

export const theme = {
  components: {
    Card: {
      borderRadius: 10,
      lineWidth: 3
    }
  }
};
