import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lowSeverity, highSeverity } from './styles';

export const lowSeverityIcon = (
  <FontAwesomeIcon icon={faExclamationCircle} style={lowSeverity} />
);
export const highSeverityIcon = (
  <FontAwesomeIcon icon={faExclamationCircle} style={highSeverity} />
);
