import { FC } from 'react';
import { Layout, Row, Col, Image, Typography } from 'antd';
import PleiadesLogo from '../../assets/PleiadesLogo.png';
import { textStyle, linkStyle, imageStyle } from './styles';
import GuardiansLogo from '../../assets/GuardiansLogo.png';

const Footer: FC = () => {
  const { Footer } = Layout;
  const { Link, Text } = Typography;

  return (
    <Footer>
      <Row>
        <Col xs={24} sm={24} lg={12} push={1}>
          <Col
            style={{
              ...textStyle,
              marginTop: -5,
              marginBottom: 12
            }}
          >
            <Col style={{ fontSize: 16, fontWeight: 'bold' }}>
              Έξυπνη Πόλη-Διαχείριση Περιβάλλοντος (Smart City-Environment)
            </Col>
            <Col style={{ fontSize: 16 }}>
              στο πλαίσιο του «Pleione: Pleiades Internet of Everything»
            </Col>

            <Text style={{ ...textStyle }}></Text>
          </Col>
          <Col style={{ ...textStyle, marginTop: -5 }}>
            Στόχος του Έργου είναι η ενιαία και πλέον αποτελεσματική διαχείριση
            του Περιβάλλοντος,
          </Col>
          <Col style={textStyle}>με έμφαση στο αστικό περιβάλλον.</Col>
          <Col style={{ marginBottom: 12 }}>
            <Link
              style={linkStyle}
              href={'https://environmental-system.info'}
              target="_blank"
            >
              Περισσότερα για το Έργο
            </Link>
          </Col>
          <Col style={{ fontSize: 16, fontWeight: 'bold' }}>
            Η Guardian Telematics SA είναι μέλος του Pleiades IoT Innovation
            cluster.
          </Col>
          <Col style={textStyle}>
            Το Pleiades IoT Cluster εστιάζει στις τεχνολογίες Internet of
            Things,
          </Col>
          <Col style={textStyle}>
            συμβάλλοντας στη δημιουργία ενός δυναμικού ελληνικού οικοσυστήματος
            IoT.
          </Col>
          <Col>
            <Link
              style={linkStyle}
              href={'https://pleiadesiot.com'}
              target="_blank"
            >
              Περισσότερα για Pleiades IoT
            </Link>
          </Col>
        </Col>
        <Col
          xs={24}
          sm={24}
          lg={{ span: 4, push: 3 }}
          push={3}
          style={imageStyle}
        >
          <Link
            style={linkStyle}
            href={require('../../assets/Poster_guardian.pdf')}
            target="_blank"
          >
            <Image src={PleiadesLogo} width="80%" preview={false} />
          </Link>
        </Col>
        <Col xs={24} sm={24} lg={{ span: 4, push: 3 }} style={imageStyle}>
          <Image src={GuardiansLogo} width="70%" preview={false} />
        </Col>
      </Row>
      <Row justify="center">
        <Col>Guardian Telematics SA © Copyright 2023. All Rights Reserved</Col>
      </Row>
    </Footer>
  );
};

export default Footer;
