import { Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import { FC, useState, createContext, useMemo } from 'react';

type Props = {
  children: React.ReactNode;
};
interface AppContextInterface {
  climateTypeCategory: string;
  climateType: string;
  sensorId: string | undefined;
  sensorType: string | undefined;
  stationName: string;
  setClimateTypeCategory: Dispatch<SetStateAction<string>>;
  setClimateType: Dispatch<SetStateAction<string>>;
  setSensorType: Dispatch<SetStateAction<string | undefined>>;
  setSensorId: Dispatch<SetStateAction<string | undefined>>;
  setStationName: Dispatch<SetStateAction<string>>;
}

export const ClimateTypeContext = createContext<AppContextInterface>({
  sensorId: '',
  sensorType: '',
  stationName: '',
  climateTypeCategory: '',
  climateType: '',
  setClimateTypeCategory: () => '',
  setClimateType: () => '',
  setSensorType: () => '',
  setSensorId: () => '',
  setStationName: () => ''
});

const ClimateTypeContextProvider: FC<Props> = ({ children }) => {
  const { type, id } = useParams();

  const CLIMATETYPETEMPERATURE = 'Temperature';
  const CLIMATETYPE = 'Meteorological';
  const [climateTypeCategory, setClimateTypeCategory] = useState(
    CLIMATETYPETEMPERATURE
  );
  const [climateType, setClimateType] = useState(CLIMATETYPE);
  const [sensorType, setSensorType] = useState(type);
  const [sensorId, setSensorId] = useState(id);
  const [stationName, setStationName] = useState('');
  const contextValue = useMemo(
    () => ({
      climateTypeCategory,
      sensorType,
      sensorId,
      stationName,
      climateType,
      setClimateTypeCategory,
      setClimateType,
      setSensorType,
      setSensorId,
      setStationName
    }),
    [
      climateTypeCategory,
      sensorType,
      sensorId,
      stationName,
      climateType,
      setClimateType
    ]
  );

  return (
    <ClimateTypeContext.Provider value={contextValue}>
      {children}
    </ClimateTypeContext.Provider>
  );
};
export default ClimateTypeContextProvider;
