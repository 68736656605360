import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import elGR from 'antd/locale/el_GR';
import { theme } from './shared/styles/baseStyles';
import ErrorPage from './components/ErrorPage/ErrorPage';
import { isAxiosError } from 'axios';
import SignalRContext from './shared/context/SignalRContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      cacheTime: 5,
      staleTime: 120000
    }
  },
  queryCache: new QueryCache({
    onError: error => {
      let subTitle = 'Κάτι πήγε στραβά';
      if (isAxiosError(error)) {
        subTitle =
          error.response?.status === 404
            ? 'Η σελίδα δε βρέθηκε'
            : error.response?.status === 500
            ? 'Γενικό Πρόβλημα'
            : subTitle;
      }
      root.render(<ErrorPage subTitle={subTitle} />);
    }
  })
});

root.render(
  <SignalRContext>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider theme={theme} locale={elGR}>
        <App />
      </ConfigProvider>
    </QueryClientProvider>
  </SignalRContext>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
