import { FC } from 'react';
import { Routes, Route, RouteProps, Navigate } from 'react-router-dom';
import SensorDetailsContainer from '../../pages/SensorDetails/SensorDetailsContainer';
import Home from '../../pages/Home/Home';
import ClimateTypeContext from '../../shared/context/ClimateTypeContext';

const Router: FC = () => {
  const routes: RouteProps[] = [
    {
      id: '1',
      path: '/',
      element: (
        <ClimateTypeContext>
          <Home />
        </ClimateTypeContext>
      )
    },
    {
      id: '2',
      path: '/details/:type/sensor/:id',
      element: (
        <ClimateTypeContext>
          <SensorDetailsContainer closeLayout={() => null} />
        </ClimateTypeContext>
      )
    },
    {
      id: '3',
      path: '*', //always the last object
      element: <Navigate to="/" />
    }
  ];

  const routeComponents = routes.map(({ path, element, id }) => (
    <Route path={path} element={element} key={id} />
  ));
  return <Routes>{routeComponents}</Routes>;
};

export default Router;
