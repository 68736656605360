import { useMemo } from 'react';
import ClimateTypeSelector from '../ClimateTypeSelector/ClimateTypeSelector';

const ClimateTypeControl = () => {
  const climateSelector = useMemo(() => <ClimateTypeSelector />, []);

  return (
    <div className="leaflet-top leaflet-right">
      <div className="leaflet-control leaflet-bar">{climateSelector}</div>
    </div>
  );
};
export default ClimateTypeControl;
