import { FC, ReactNode, useCallback, useState } from 'react';
import { Card, Col, Divider, Row } from 'antd';
import NewWindow from 'react-new-window';
import { headCard, bodyCard } from './styles';
import ConditonalWrapper from '../ConditionalWrapper/ConditionalWrapper';

interface CardProps {
  children: ReactNode;
  title: string | ReactNode | undefined;
  extra: ReactNode;
}

const GridCard: FC<CardProps> = ({ children, title, extra }: CardProps) => {
  const [isOpen, setOpenState] = useState(false);
  const close = useCallback(() => setOpenState(false), []);

  const content = (
    <Card
      type="inner"
      title={title}
      headStyle={headCard}
      bodyStyle={bodyCard}
      bordered={false}
      hoverable
      extra={extra}
    >
      {children}
    </Card>
  );

  return (
    <ConditonalWrapper
      condition={isOpen}
      wrapper={(content: ReactNode) => (
        <NewWindow onUnload={close}>
          <Divider />
          <Row justify="center">
            <Col span={23}>{content}</Col>
          </Row>
        </NewWindow>
      )}
    >
      {content}
    </ConditonalWrapper>
  );
};

export default GridCard;
