import { FC } from 'react';
import { Image, Row, Col, Divider, Grid } from 'antd';
import { useNavigate } from 'react-router-dom';
import EnvironMentalSystemLogo from '../../assets/EnvironmentalSystemLogo.png';
import GGEKLogo from '../../assets/GGEKLogo.jpg';
import {
  headerStyleLg,
  headerStyleXs,
  betaVersionStyle,
  imageStyle,
  textStyleLg,
  textStyleMd
} from './styles';
const Header: FC = () => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const navigate = useNavigate();

  return (
    <>
      <style>
        {`
          @keyframes slideText {
            from {
              transform: translateX(0%);
            }
            to {
              transform: translateX(100%);
            }
          }
        `}
      </style>
      <Row style={xs ? headerStyleXs : headerStyleLg}>
        <Col xs={24} md={12} lg={2}>
          <Image
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(`/`)}
            src={EnvironMentalSystemLogo}
            preview={false}
            width={90}
          ></Image>
        </Col>
        <Col style={imageStyle} xs={24} md={12} lg={2}>
          <Image src={GGEKLogo} preview={false} width={280}></Image>
        </Col>
        <Col
          style={xs ? textStyleMd : textStyleLg}
          xs={{ span: 24 }}
          md={24}
          lg={24}
          xl={20}
          pull={1}
        >
          Σύστημα Παρακολούθησης Περιβαλλοντικών Δεδομένων
        </Col>
      
      </Row>
      <Divider />
    </>
  );
};

export default Header;
