/* eslint-disable react/prop-types */

import { Descriptions } from 'antd';
import { Line } from '@ant-design/plots';
import { useEffect, useState } from 'react';

import { descriptionsItem, description } from './styles';

const LineChart = ({ lineChartData, descriptionValues, selectedItems }) => {
  const initialConfig = {
    autoFit: true,
    xField: 'time',
    yField: 'value',
    seriesField: 'label',
    tooltip: {
      showMarkers: false
    },
    slider: {
      start: 0.2,
      end: 1
    },
    xAxis: {
      title: {
        text: lineChartData[descriptionValues.length - 1]?.date,
        position: 'bottom',
        offset: 0,
        spacing: 40,
        style: {
          fontSize: 18,
          fontWeight: 900,
          textAlign: 'start'
        }
      }
    },
    legend: {
      position: 'top',
      selected: selectedItems
    },
    interactions: [
      {
        type: 'marker-active'
      }
    ]
  };

  const [config, setConfig] = useState(initialConfig);
  const [descValue, setDescValue] = useState(
    descriptionValues[descriptionValues.length - 1]
  );
  useEffect(() => {
    setConfig(initialConfig);
    setDescValue(descriptionValues[descriptionValues.length - 1]);
  }, [descriptionValues, lineChartData, selectedItems]);

  return (
    <>
      <Descriptions
        size="middle"
        labelStyle={descriptionsItem}
        style={description}
      >
        <Descriptions.Item
          contentStyle={descriptionsItem}
          label="Ελάχιστη Τιμή"
        >
          {descValue?.min}
        </Descriptions.Item>
        <Descriptions.Item contentStyle={descriptionsItem} label="Μέσος Όρος">
          {descValue?.avg}
        </Descriptions.Item>
        <Descriptions.Item contentStyle={descriptionsItem} label="Μέγιστη Τιμή">
          {descValue?.max}
        </Descriptions.Item>
      </Descriptions>
      <Line
        {...config}
        data={lineChartData}
        onReady={plot => {
          plot.on('legend-item:click', e => {
            let updatedConfig;
            updatedConfig = {
              ...initialConfig,
              yAxis: { max: undefined, min: undefined }
            };

            setConfig(updatedConfig);
          });
          plot.on('legend-item-name:click', e => {
            const updatedConfig = { ...config };
            Object.keys(updatedConfig.legend.selected).forEach(item => {
              if (item === e.gEvent.shape.cfg.attrs.text) {
                setDescValue(
                  descriptionValues.find(
                    item => item.label === e.gEvent.shape.cfg.attrs.text
                  )
                );
                return (updatedConfig.legend.selected[item] = true);
              }
              return (updatedConfig.legend.selected[item] = false);
            });
            setConfig(updatedConfig);
          });
        }}
      />
    </>
  );
};

export default LineChart;
