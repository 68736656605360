export const textStyle: React.CSSProperties = {
  fontSize: 15
};
export const linkStyle: React.CSSProperties = {
  fontSize: 15
};

export const imageStyle: React.CSSProperties = {
  paddingBottom: 50,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex'
};
