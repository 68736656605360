import { Sensor } from '../../types/types';
import { Row, Typography } from 'antd';
import { row, label, paragraph, noDataRow } from './styles';
import { FC } from 'react';
import { TransformDateTimeService } from '../../services/TransformDateTimeService';

type Props = {
  sensor: Sensor;
};
const { Paragraph, Text } = Typography;
const PopUpDetails: FC<Props> = ({ sensor }: Props) => {
  const transformDateTime = new TransformDateTimeService();

  const data = [
    {
      key: '1',
      label: sensor.dateName,
      value: sensor.date
    },
    {
      key: '2',
      label: sensor.timeName,
      value: transformDateTime.timeEpochToGR((sensor as Sensor).dateTime)
    },
    {
      key: '3',
      label: (sensor as Sensor).climateName,
      value: (sensor as Sensor).climateValue
    }
  ];

  return (
    <>
      <Row style={row}>{sensor?.stationName}</Row>
      {sensor?.climateValue !== null ? (
        data.map(item => (
          <Paragraph key={item.key} style={paragraph}>
            <Text strong style={label}>
              {item.label}:{' '}
            </Text>
            <Text>{item.value}</Text>
          </Paragraph>
        ))
      ) : (
        <Row style={noDataRow}>Δεν υπάρχουν Δεδομένα</Row>
      )}
    </>
  );
};

export default PopUpDetails;
